import React from 'react';
import {useField} from "formik";
import TextField from "@mui/material/TextField";

//todo: to see how to define prop-types for this component

const style = {
    "& .MuiOutlinedInput-root": {
      "&.Mui-focused fieldset": {
        borderColor: "#000"
      }
    }
  }  

const JumboTextField = (props) => {
    const [field, meta] = useField(props);
    var multiline1=''
    multiline1 = props.multiline;
    const errorText = meta.error && meta.touched ? meta.error : '';
    return (
      <TextField
      {...field}
      {...props} // Spread all props to the TextField
      helperText={errorText}
      error={!!errorText}
      sx={style}
    />
    );
};

export default JumboTextField;