import React, {useContext,useState, useRef  } from 'react';
import JumboDemoCard from "@jumbo/components/JumboDemoCard";
import Box from "@mui/material/Box";
import {Alert, Grid} from "@mui/material";
import { CustomProvider } from 'app/layouts/vertical-default/VerticalDefault';
import SweetAlert from 'app/pages/components/mui/Alerts/SweetAlert';
import { deployuser, postRequest, submitManualPayment } from 'backendServices/ApiCalls';
import { Form, Formik} from "formik";
import * as yup from "yup";
import './CollapsibleBox.css'; // Import CSS for transition animation
import JumboTextField from '@jumbo/components/JumboFormik/JumboTextField';
import { LoadingButton } from '@mui/lab';
import Cards from 'react-credit-cards-2';
import Stripe from 'stripe';
import {
  formatCreditCardNumber,
  formatCVC,
  formatExpirationDate,
  formatFormData
} from "./utils"
import 'react-credit-cards-2/dist/es/styles-compiled.css';

const validationSchema = yup.object({
  amount: yup.number()
    .required('Amount is required'),
    number: yup.number().required('Card number is required'),
    name: yup.string().required('Name is required'),
    expiry: yup.string().required('Expiry date is required'),
    cvc: yup.number().required('CVC is required'),
});

const stripe = Stripe('pk_test_51NyDEKD0j3tryzmxoOChsFE706ZJNd1hBnCL1u6n8TcdnS2xl8mESAccV82u9oR3PEq3oMOuO3MFquB0qPc09efm009Y4TdUgC'); // Replace with your Stripe publishable key




const Investment = () => {

  const [alertData, setalertData] = React.useState({
    show:false,
    message:"",
    variant:""
})
  const {loginUserData, setloginUserData} = useContext(CustomProvider);
  let userData = loginUserData
  const [number, setNumber] = useState('');
  const [name, setName] = useState('');
  const [expiry, setExpiry] = useState('');
  const [cvc, setCvc] = useState('');
  const [issuer, setIssuer] = useState('');
  const [focused, setFocused] = useState('');
  const [formData, setFormData] = useState(null);
  const formRef = useRef(null);

  const initialstates = {
    amount: '199',
    number: number || '',
    name: name || '',
    expiry: expiry || '',
    cvc: cvc || ''
  }
  
  const handleCallback = ({ issuer }, isValid) => {
    if (isValid) {
      setIssuer(issuer);
    }
  };

  const handleInputFocus = ({ target }) => {
    setFocused(target.name);
  };


  const handleInputChange = ({ target }) => {
    console.log('hiiiiiiiiii')
    let value = target.value;
    const name = target.name;

    if (name === 'number') {
      value = formatCreditCardNumber(value);
      console.log('numberrrrr',value)
    } else if (name === 'expiry') {
      value = formatExpirationDate(value);
    } else if (name === 'cvc') {
      value = formatCVC(value);
    }

    switch (name) {
      case 'number':
        setNumber(value);
        break;
      case 'name':
        setName(value);
        break;
      case 'expiry':
        setExpiry(value);
        break;
      case 'cvc':
        setCvc(value);
        break;
      default:
        break;
    }
  };

  const handleSubmit = async (data,setSubmitting,resetForm) => {
    try{
    // Validate card details using Stripe.js
    const token = await stripe.tokens.create({
      card: {
        name: data?.name, // Replace with the value from your card number input
        number: data?.number, // Replace with the value from your card number input
        exp_month: parseInt(data?.expiry.substring(0, 2), 10), // Parse the month
        exp_year: parseInt(data?.expiry.substring(3), 10), // Parse the year
        cvc: data?.cvc, // Replace with the value from your CVC input
      },
    });

     if(token?.id)
     {

      let params = {
        amount: data?.amount * 100,
        currency: userData.country === 'US' ? 'usd' : 'cad',
        source: token?.id,
        description: `Joining fee paid by user ${loginUserData.username}`
      };

      postRequest('/deployuser',params, (response) => {
        if(response?.data?.status === "success")
        {
          setSubmitting(false)
          resetForm()
          setalertData({
            show:true,
            message:"You have successfully paid the joining fees",
            variant:'success'
          })
          setloginUserData((prevState) => ({
            ...prevState,
            status: 'approved',
          }));
        }else{
          setSubmitting(false)
          setalertData({
            show:true,
            message:response?.data?.message,
            variant:'error'
          })
        }
      }, (error) => {
          console.log(error?.response?.data);
      })
     }
  }
  catch(e){
    setSubmitting(false)
    setalertData({
      show:true,
      message:e.message,
      variant:'error'
    })
  }
  };


    return (
      <Grid container spacing={2}  alignItems="center" justifyContent="center" >
         {
            alertData.show && (<SweetAlert alertData={alertData} setalertData={setalertData} />)
          }
     
      <Grid item sm={8} >
        <JumboDemoCard  title={"Pay Joining Fee"}
                       wrapperSx={{backgroundColor: 'background.paper', pt: 0}}>


            <Box 
              key='Payment'
                sx={{
                    display: 'flex',
                    flexDirection: 'column',                  
                }}
                alignItems="center"
            >

{

userData.status === 'pending' ? (
<>

<Cards
      number={number}
      name={name}
      expiry={expiry}
      cvc={cvc}
      focused={focused}
      callback={handleCallback}
    />


        <Box sx={{ mt: 2, p: 2, border: '1px solid #ccc', borderRadius: '4px' }} className="App-payment">
        <Formik
                validateOnChange={false}
                enableReinitialize = 'true'
                initialValues={initialstates}
                validationSchema={validationSchema}
                onSubmit={(data, {setSubmitting,resetForm}) => {
                            setSubmitting(true);
                            handleSubmit(data, setSubmitting,resetForm);
                        }}
            >
{({isSubmitting, values}) => (
            <Form style={{textAlign: 'left'}} noValidate autoComplete='off' >

            <Grid container spacing={2} ref={formRef}>

            <Grid item xs={12}>
                  <JumboTextField
                    fullWidth
                    name="amount"
                    label={`Amount (${userData.country === 'US' ? 'USD' : 'CAD'})`}
                    type="number"
                    InputProps={{
                      readOnly: true,
                  }}
                  />
                </Grid>


                <Grid item xs={12}>
                  <JumboTextField
                    fullWidth
                    label={`Card Number`}
                    type="tel"
                    name="number"
                    className="form-control"
                    value={number}
                    required
                    onChange={handleInputChange}
                    onFocus={handleInputFocus}
                  />
                </Grid>
                <Grid item xs={12}>
                  <JumboTextField
                    fullWidth
                    label={`Name on card`}
                    type="text"
                    name="name"
                    className="form-control"
                    required
                    onChange={handleInputChange}
                    onFocus={handleInputFocus}
                  />
                </Grid>

                <Grid item xs={6}>
                  <JumboTextField
                    fullWidth
                    label={`Card Expiry`}
                    type="tel"
                    name="expiry"
                    value={expiry}
                    className="form-control"
                    pattern="\d\d/\d\d"
                    required
                    onChange={handleInputChange}
                    onFocus={handleInputFocus}
                  />
                </Grid>


                <Grid item xs={6}>
                  <JumboTextField
                    fullWidth
                    label={`CVC`}
                    type="tel"
                    name="cvc"
                    value={cvc}
                    className="form-control"
                    pattern="\d{3,4}"
                    required
                    onChange={handleInputChange}
                    onFocus={handleInputFocus}
                  />
              <input type="hidden" name="issuer" value={issuer} />

                </Grid>





                <Grid item sm={12}>
                             <LoadingButton
                                    fullWidth
                                    type="submit"
                                    variant="contained"
                                    size="large"
                                    sx={{mb: 3}}
                                    loading={isSubmitting}
                                >Pay Now</LoadingButton>
                </Grid>

              </Grid>

  </Form>
)}
  </Formik>

        </Box>

</>
)

:
(<Alert severity="warning">You have already paid the joining fee</Alert>)
}


              
            </Box>

        </JumboDemoCard>
      </Grid>
      </Grid>
    );
};

export default Investment;








