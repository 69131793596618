import React, { useContext, useEffect, useState } from 'react';
import Box from '@mui/material/Box';
import JumboDemoCard from '@jumbo/components/JumboDemoCard/JumboDemoCard';
import TreeView from '@mui/lab/TreeView';
import { getHierarchyData, postRequest } from 'backendServices/ApiCalls';
import "./unileveltree.css";
import Div from '@jumbo/shared/Div/Div';
import { Button, CircularProgress, Grid } from '@mui/material';
import { Link, useParams } from 'react-router-dom';
import { CustomProvider } from 'app/layouts/vertical-default/VerticalDefault';
import KeyboardDoubleArrowUpIcon from '@mui/icons-material/KeyboardDoubleArrowUp';

  const TreeNode = ({ data }) => (
    <ul>
      {data.map((item) => (
        <li key={item.id}>
          <img src={item.img} style={{ width: 50 }} />
          <br></br>
          <Link to={`/treeview/${item.randomcode}`} className="targaryen">
            {item.title}
          </Link>
          {item.children && <TreeNode data={item.children} />}
        </li>
      ))}
    </ul>
  );
  

const UnilevelTreeView = () => {


  const [userHierarchyData,setUserHierarchyData]=useState([])
  const [isLoading, setisLoading] = useState(true);
  const {loginUserData} = useContext(CustomProvider);
  const {randomcode} = useParams();
  let userrandomcode = loginUserData?.randomcode
  if(randomcode)
  {
    userrandomcode = randomcode
  }

  const HierArchyData =()=>{
    console.log('eeeeeeee', userrandomcode)
    let params = {
      userrandomcode
    }
    setisLoading(true);
    postRequest('/gethierarchy',params,(response) => {
        setisLoading(false);
      console.log('tresss', response?.data?.data)
      setUserHierarchyData(response?.data?.data)
      }, (error) => {
        setisLoading(false);
          console.log(error?.response?.data);
      })
  }
  
  useEffect(()=>{
      HierArchyData();
  },[randomcode])
  
  if (isLoading) {
    return (
      <Div
        sx={{
          display: "flex",
          minWidth: 0,
          alignItems: "center",
          alignContent: "center",
          height: "100%",
        }}
      >
        <CircularProgress sx={{ m: "-40px auto 0" }} />
      </Div>
    );
  }
  return (
    <JumboDemoCard
      title="Tree View"
      wrapperSx={{ backgroundColor: 'background.paper', pt: 0,display:'block', overflowX:'scroll' }}
    >
      <Grid container sm={12}>
        <Grid
          item
          sm={12}
          xs={12}
          style={{
            display: "flex",
            justifyContent: "flex-end",
            marginBottom: 20,
            marginTop: -50,
            
          }}
        >
{randomcode && (<Button component={Link} sx={{position:'absolute'}} to="/treeview" variant='contained' endIcon={<KeyboardDoubleArrowUpIcon />} size='small'>Back to top</Button>)}

        </Grid>
      </Grid>
    <div id="container" className="tree-container">
      {userHierarchyData.map((data) => (
        <div className="tree" key={data.id}>
          <TreeNode data={[data]} />
        </div>
      ))}
    </div>

    </JumboDemoCard>
  );
};

export default UnilevelTreeView;
