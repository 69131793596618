import React, { useEffect } from "react";
import List from "@mui/material/List";
import {
  Autocomplete,
  Box,
  Button,
  InputAdornment,
  Grid,
  MenuItem,
  TextField,
  InputLabel,
  Select,
  FormControl,
  Popover,
  Avatar,
  Typography,
  ListItem,
  ListItemText,
  CircularProgress,
  Alert
} from "@mui/material";
import JumboCardQuick from "@jumbo/components/JumboCardQuick";
import { useState } from "react";
import { countries } from "app/pages/components/mui/AutoCompletes/data";
import { useContext } from "react";
import { UserData } from "../UserProfile";
import JumboTextField from "@jumbo/components/JumboFormik/JumboTextField";
import { ErrorMessage, Form, Formik } from "formik";
import * as yup from "yup";
import { postRequest, updateProfileData, updateProfilePicture } from "backendServices/ApiCalls";
import { LoadingButton } from "@mui/lab";
import SweetAlert from "app/pages/components/mui/Alerts/SweetAlert";
import { CustomProvider } from "app/layouts/vertical-default/VerticalDefault";
import Div from "@jumbo/shared/Div";
import CameraAltIcon from '@mui/icons-material/CameraAlt';

const validationSchema = yup.object({
  firstname: yup.string("Enter first name").required("first name is required"),
  lastname: yup.string("Enter last name").required("last name is required"),
  email: yup
    .string("Enter email address")
    .email("Invalid email address")
    .required("Email is required"),
  mobile: yup.string("Enter mobile number").required("Mobile is required"),

});

const About = () => {
  const [country, setCountry] = useState("");
  const { loginUserData, setloginUserData } = useContext(CustomProvider);
  const [anchorEl, setAnchorEl] = useState(null);
  let userData = loginUserData;
  const [selectedCountry, setSelectedCountry] = useState(null);
  const [isBtnLoading, setBtnIsLoading] = useState(false);
  const [isButtonDisabled, setIsButtonDisabled] = useState(false);
  const [image, setImage] = useState(null);
  const [licenseNumber, setLicenseNumber] = useState('');


  const [alertData, setalertData] = React.useState({
    show: false,
    message: "",
    variant: "",
  });
  const onSubmitForm = (data, setSubmitting) => {
    let params = {
      firstname: data.firstname,
      lastname: data.lastname,
      email: data.email,
      mobile: data.mobile,
    };
    updateProfileData(
      params,
      (response) => {
        if (response?.data?.status === "error") {
          setalertData({
            show: true,
            message: response?.data?.message,
            variant: "error",
          });
          setSubmitting(false);
        } else if (response?.data?.status === "success") {
          setalertData({
            show: true,
            message: response?.data?.message,
            variant: "success",
          });
          setSubmitting(false);
          setloginUserData((prevState) => ({
            ...prevState,
            country: country.code,
            email: data.email,
            mobile: data.mobile,
            firstname: data.firstname,
            lastname: data.lastname,
          }));
        }
      },
      (error) => {
        console.log(error?.response?.data);
      }
    );
  };
  useEffect(() => {
    if (userData && userData.country) {
      const country = countries.find(
        (country) =>
          country.code.toLowerCase() === userData.country.toLowerCase()
      );
      setSelectedCountry(country);
    }
  }, [userData, countries]);

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };


  const handleUpload = (e) => {
    if (e.target.files && e.target.files.length > 0) {
      const selectedFile = e.target.files[0];
      if (selectedFile.type.startsWith("image/")) {
        setImage(selectedFile);
      } else {
        setalertData({
          show: true,
          message: 'Invalid file type. Please select an image file.',
          variant: "error"
        })
      }
    }
  }

  const handleSubmit = (e) => {
    e.preventDefault();
    setBtnIsLoading(true);
    setIsButtonDisabled(true);
    if (!image) {
      setalertData({
        show: true,
        message: 'Invalid file type. Please select an image file.',
        variant: "error"
      })
      setBtnIsLoading(false);
      setIsButtonDisabled(false);
      return;
    }



    const reader = new FileReader();
    reader.onloadend = () => {
      const base64Data = reader.result; // Extract base64 encoded string
      const formData = new FormData();
      formData.append('image', base64Data);
      formData.append('license_number', licenseNumber);

      postRequest('/uploadlicense', formData, (response) => {
        console.log(response);
        setBtnIsLoading(false);
        setIsButtonDisabled(false);
        if (response?.data?.status === "error") {
          setalertData({
            show: true,
            message: response?.data?.message,
            variant: "error"
          })
        }
        else if (response?.data?.status === "success") {
          setloginUserData((prevState) => ({
            ...prevState,
            license_status: 'submitted',
          }));

          setAnchorEl(null);
          setalertData({
            show: true,
            message: response?.data?.message,
            variant: "success"
          })
        }
        else {
          setalertData({
            show: true,
            message: 'Something went wrong please try again later',
            variant: "error"
          })

        }
      }, (error) => {
        setBtnIsLoading(false);
        setIsButtonDisabled(false);
        console.log(error?.response?.data);
        setalertData({
          show: true,
          message: 'Something went wrong please try again',
          variant: "error"
        })
      });
    };

    reader.readAsDataURL(image);
  }

  if (userData.firstname === "") {
    return <div>laoding</div>;
  }

  const open = Boolean(anchorEl);
  const id = open ? 'user-popover' : undefined;
  return (
    <JumboCardQuick title={"Update Profile"}>
      <Grid container sm={12}>
        <Grid
          item
          sm={12}
          xs={12}
          style={{
            display: "flex",
            justifyContent: "flex-end",
            marginBottom: 20,
            marginTop: -70,
          }}
        >
          <Button
            variant="contained"
            size="small"
            sx={{ padding: "10px" }}
            onClick={handleClick}
          >
            Upload Licence
          </Button>
          <Popover
            id={id}
            open={open}
            anchorEl={anchorEl}
            onClose={handleClose}
            anchorOrigin={{
              vertical: "center",
              horizontal: "right",
            }}
            sx={{
              "& .MuiPaper-root": {
                border: 1,
                borderColor: "divider",
              },
            }}
          >

            <Div sx={{ p: 3, pb: 2, maxWidth: 300 }}>
              {userData.license_status === "pending" || userData.license_status === "rejected" ? (
                <List>
                  <Box
                    component="form"
                    onSubmit={handleSubmit}
                    sx={{
                      display: "flex",
                      flexDirection: "column",
                      "& .MuiTextField-root": { width: "34ch" },
                    }}
                    alignItems="center"
                  >

                    {
                      userData.license_status === "rejected" ? (
                        <Alert severity="error">Previous uploaded document was rejected. with reason <b>{userData.rejectreason}</b> </Alert>

                      ) : null
                    }
                    <ListItem sx={{ px: 0, pb: 0 }}>
                      <ListItemText
                        primary={
                          <TextField
                            fullWidth
                            label='License Number'
                            value={licenseNumber}
                            name="license_number"
                            type="text"
                            margin="normal"
                            onChange={(e) => setLicenseNumber(e.target.value)}

                          />
                        }
                      />
                    </ListItem>

                    <ListItem sx={{ px: 0, pb: 0 }}>
                      <ListItemText
                        primary={
                          <TextField
                            onChange={handleUpload}
                            name="file"
                            type="file"
                            margin="normal"
                            InputProps={{
                              endAdornment: (
                                <CameraAltIcon
                                  fontSize={"small"}
                                  color={"success"}
                                />
                              ),
                            }}
                          />
                        }
                      />
                    </ListItem>

                    <Button
                      style={{ width: "50%", marginTop: 20 }}
                      variant="contained"
                      type="submit"
                      disabled={isButtonDisabled}
                    >
                      {isBtnLoading ? (
                        <CircularProgress size={24} color="inherit" />
                      ) : (
                        "Update"
                      )}
                    </Button>
                  </Box>
                </List>
              )
                :
                userData.license_status === "submitted" ? (
                  <Alert severity="warning">you have already submmited your license. <br />It is in processing and will be approved by admin.</Alert>

                )
                  :
                  (
                    <Alert severity="success">Your license is already updated and approved</Alert>

                  )
              }

            </Div>
          </Popover>
        </Grid>
      </Grid>
      {alertData.show && (
        <SweetAlert alertData={alertData} setalertData={setalertData} />
      )}
      <List disablePadding>
        <Formik
          validateOnChange={true}
          enableReinitialize="true"
          initialValues={{
            firstname: userData.firstname || "",
            lastname: userData.lastname || "",
            email: userData.email || "",
            mobile: userData.mobile || "",
          }}
          validationSchema={validationSchema}
          onSubmit={(data, { setSubmitting }) => {
            setSubmitting(true);
            onSubmitForm(data, setSubmitting);
          }}
        >
          {({ isSubmitting, values, setFieldValue }) => (
            <Form style={{ textAlign: "left" }} noValidate autoComplete="off">
              <Grid container spacing={2}>
                <Grid item sm={6} xs={12}>
                  <JumboTextField
                    fullWidth
                    name="firstname"
                    label="First Name"
                    type="text"
                  />
                </Grid>
                <Grid item sm={6} xs={12}>
                  <JumboTextField
                    fullWidth
                    label="Last Name"
                    name="lastname"
                    type="text"
                  />
                </Grid>

                <Grid item sm={6} sx={{ width: { xs: "100%" } }}>
                  <JumboTextField
                    fullWidth
                    label="Email"
                    name="email"
                    type="email"
                  />
                </Grid>

                <Grid item sm={6} sx={{ width: { xs: "100%" } }}>
                  <JumboTextField
                    fullWidth
                    label="Mobile"
                    name="mobile"
                    type="tel"
                  />
                </Grid>

                <Grid
                  item
                  sm={6}
                  sx={{ width: { xs: "100%" }, justifyContent: "end" }}
                >
                  <LoadingButton
                    fullWidth
                    type="submit"
                    variant="contained"
                    size="large"
                    sx={{ mb: 3 }}
                    loading={isSubmitting}
                  >
                    Submit
                  </LoadingButton>
                </Grid>
              </Grid>
            </Form>
          )}
        </Formik>
      </List>
    </JumboCardQuick>
  );
};

export default About;
